import React from 'react';
import { Select, SelectProps, InputLabel, FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';
import { Colors } from '../../../../styles/colors';

type SelectInputProps = {
  name: string;
  noMargin?: boolean;
  children: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & SelectProps;

export const SelectInput = ({ name, label, noMargin, defaultValue, children, onChange, ...rest }: SelectInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin}>
          {/* <pre>{JSON.stringify({ fieldState }, null, 2)}</pre> */}
          <FormControl fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <SelectFieldExtended
              labelId="select-label"
              label={label}
              {...field}
              {...rest}
              onChange={(event) => {
                field.onChange(event);
                if (onChange) {
                  onChange(event);
                }
              }}
              fullWidth
              color={fieldState.error ? 'error' : 'primary'}
            >
              {React.Children.map(children, (child) =>
                React.isValidElement(child) ? (
                  <MenuItem value={child?.props.value}>{child?.props.children}</MenuItem>
                ) : null
              )}
            </SelectFieldExtended>
            {fieldState.error ? <ErrorMessage>{fieldState.error.message}</ErrorMessage> : ''}
          </FormControl>

          {/* <pre>{JSON.stringify({ field, fieldState }, null, 2)}</pre> */}
        </FieldWrapper>
      )}
    />
  );
};

// bug when using the default MUY Select component
// MAX STACK SIZE EXCEEDED
export const SelectInputInModal = ({ name, label, noMargin, defaultValue, children, ...rest }: SelectInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin}>
          <FormControl fullWidth>
            <ExtentedSelect {...field}>{children}</ExtentedSelect>
          </FormControl>
        </FieldWrapper>
      )}
    />
  );
};

const ExtentedSelect = styled.select`
  width: 100%;
  height: 58px;
  padding: 0 8px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  &:focus {
    outline: none;
    border: 2px solid ${Colors.Contrast};
    border-left-width: 6px !important; // override inline-style
  }
`;

const SelectFieldExtended = styled(Select)`
  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;

export const SelectItem = ({ value, children }: any) => {
  return <MenuItem value={value}>{children}</MenuItem>;
};

const ErrorMessage = styled.div`
  padding: 0 0 0 8px;
  font-size: 16px;
  color: ${Colors.Error};
`;
